
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmTabs from '@/components/shared/TmTabs.vue'

export default defineComponent({
  components: {
    PageContent,
    TmTabs,
  },
  setup() {
    const currentRoute = ref('allowed-emails')
    const tabs = ref([
      {
        name: 'allowed-emails',
        label: 'Allowed emails',
        to: { name: 'base.services.emailToSms.allowedEmails' },
      },
      {
        name: 'send-receive-settings',
        label: 'Send & receive settings',
        to: { name: 'base.services.emailToSms.sendAndReceiveSettings' },
      },
      {
        name: 'distribution',
        label: 'Distribution lists',
        to: { name: 'base.services.emailToSms.distributionLists' },
      },
    ])

    return {
      currentRoute,
      tabs,
    }
  },
})
